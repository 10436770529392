<template>
  <div class="Recharge">
    <van-nav-bar
        title="充值"
        left-arrow
        @click-left="onClickLeft"
    />
    <div class="wallent_content">
      <van-cell :border="false" title-class="content_money">
        <template #title>
          我的联惠金：<span>&yen;</span>{{userinfo.credit5}}
        </template>
      </van-cell>
      <van-form @submit="onSubmit">
        <van-cell title="充值金额" :border="false"></van-cell>
        <van-field
          v-model="money"
          ref="moneys"
          name="money"
          type="number"
          :error="noMoney"
          :error-message="noText"
        >
          <template #label>&yen;</template>
        </van-field>
        <van-field :border="false">
          <template #input>
            <div class="money_list">
              <van-button plain hairline native-type="button" @click="checked(100)" :type="moneychecked == 100 ? 'primary' : ''">100</van-button>
              <van-button plain hairline native-type="button" @click="checked(200)" :type="moneychecked == 200 ? 'primary' : ''">200</van-button>
              <van-button plain hairline native-type="button" @click="checked(500)" :type="moneychecked == 500 ? 'primary' : ''">500</van-button>
              <van-button plain hairline native-type="button" @click="checked(1000)" :type="moneychecked == 1000 ? 'primary' : ''">1000</van-button>
              <van-button plain hairline native-type="button" @click="checked(2000)" :type="moneychecked == 2000 ? 'primary' : ''">2000</van-button>
              <van-button plain hairline native-type="button" @click="checked(5000)" :type="moneychecked == 5000 ? 'primary' : ''">5000</van-button>
              <van-button plain hairline native-type="button" @click="checked(10000)" :type="moneychecked == 10000 ? 'primary' : ''">10000</van-button>
            </div>
          </template>
        </van-field>
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "Recharge",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      money: 100, // 充值金额
      moneychecked: 100,
      noMoney: false,
      noText: "",
      userinfo: {},
      param: {}, // 支付参数
    }
  },
  mounted() {
    window.payOk = this.payOk; // 支付成功 供外部调用
    this.getinfo();
  },
  methods: {
    // 获取微信支付参数
    getWXpayParam() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "team/pay/prepayid",
          _that.$qs.stringify({
            money: _that.money,
          })
        )
        .then(response => {
          if (response.data.code == 100000) {
            _that.param = {
              appId: response.data.data.appId,
              timeStamp: response.data.data.timeStamp,
              nonceStr: response.data.data.nonceStr,
              package: response.data.data.package,
              signType: response.data.data.signType,
              paySign: response.data.data.paySign
            }
          }
        })
    },
    // 获取用户余额
    getinfo() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      _that.$axios
        .post(_that.$store.state.domain + "team/user/get_info")
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.userinfo = res.data.data;
          }else {
            _that.$toast(res.data.msg ? res.data.msg : "获取数据失败");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    // 执行充值接口
    onSubmit() {
      let _that = null;
      _that = this;
      if(_that.money.length <= 0 || parseFloat(_that.money) <= 0) {
        _that.noMoney = true;
        _that.noText = "请输入充值金额";
        _that.$refs.moneys.focus();
        return false;
      }else {
        _that.noMoney = false;
        _that.noText = "";
      }
      // 获取支付参数
      _that.$axios
        .post(
          _that.$store.state.domain + "team/pay/prepayid",
          _that.$qs.stringify({
            money: _that.money,
          })
        )
        .then(response => {
          if (response.data.code == 100000) {
            _that.param = {
              appId: response.data.data.appId,
              timeStamp: response.data.data.timeStamp,
              nonceStr: response.data.data.nonceStr,
              package: response.data.data.package,
              signType: response.data.data.signType,
              paySign: response.data.data.paySign
            }

            window.onwxpay(_that.param)
          }
        })
    },
    checked(money) {
      this.moneychecked = money;
      this.money = money;
    },
    // 返回支付结果
    payOk() {
      this.$toast("支付成功");
      this.$router.replace({ name: "PaySuccess" });
    },
  }
}
</script>
<style lang="less">
.Recharge {
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  .van-nav-bar {
    .van-icon {
      color: #333;
    }
    .van-nav-bar__text {
      color: #333;
    }
  }
  .wallent_content{
    padding-bottom: 60px;
    overflow: hidden;
    .van-cell {
      .van-cell__title {
        flex: 1;
        text-align: center;
      }
      .content_text {
        padding-top: 20px;
        color: #333;
        font-size: 16px;
      }
      .content_money {
        padding-top: 10px;
        color: #333;
        font-size: 16px;
        span {
          font-size: 12px;
          margin-right: 5px;
        }
      }
    }
    .van-form {
      margin-top: 20px;
      .van-cell {
        .van-cell__title {
          text-align: left;
          font-weight: 500;
        }
      }
      .van-field {
        line-height: 30px;
        font-weight: bold;
        font-size: 20px;
        .van-field__label {
          flex: none;
          width: 20px;
        }
        .van-field__value {
          flex: 1;
          .money_list {
            padding-top: 10px;
            width: 100%;
            overflow: hidden;
            .van-button {
              margin: 0 10px 10px 0;
              width: 70px;
            }
          }
        }
      }
    }
  }
}
</style>